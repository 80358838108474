import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit"; 
import { IAppState, IFileResource } from "../models/IAppState";
import { IInitAppState } from "../models/IInitAppState";  
import IServerURIs from "../models/configuration/servers/IServerURIs"; 

const initialState = {
    page: '/',
    loading: true,
    accessToken: null,
    expirationDateTimeUTC: new Date().toISOString(),
    encompassRoles: [],
    userPersonas: [],
    configId: null,
    isAdmin: false,
    needSetupRedirect: false,
    attachments: [], 
    loanId: null,
    userId: null,
    userFullName: null, 
    clientCode: null,
    serverUris: null 
} satisfies IAppState as IAppState;

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setPage(state, action: PayloadAction<string>) {
            state.page = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },  
        setConfigId(state, action: PayloadAction<string>) {
            state.configId = action.payload;
        }, 
        setNeedSetupRedirect(state, action: PayloadAction<boolean>) {
            state.needSetupRedirect = action.payload
        },  
        addAttachments(state, action: PayloadAction<Array<IFileResource>>) {
            action.payload.forEach((attachment) => {
                if (!state.attachments.some(a => a.name === attachment.name && a.source === attachment.source))
                    state.attachments.push(attachment);
            })
        },
        clearAttachments(state) {
            state.attachments = [];
        },
        removeAttachment(state, action: PayloadAction<string>) {
            state.attachments = state.attachments.filter(item => item.id !== action.payload);
        },

        initAppStateFromOrigin(state, action: PayloadAction<IInitAppState>) {
            state.accessToken = action?.payload?.authToken;
            state.expirationDateTimeUTC = action?.payload?.authExpires;
            state.configId = action?.payload?.configId;
            state.userId = action?.payload?.userId?.substring(0, action?.payload?.userId.indexOf("@")) ?? null;
            state.userFullName = action?.payload?.userFullName ?? null;
            state.encompassRoles = action?.payload?.encompassRoles ?? [];
            state.userPersonas = action?.payload?.personas;
            state.loanId = action?.payload?.loanID;
            if (action?.payload?.personas?.length > 0) {
                action?.payload?.personas?.forEach((r) => {
                    if (r.toLowerCase().includes("admin")) {
                        state.isAdmin = true;
                    }
                })
            }
        },
        setClientCode(state, action: PayloadAction<string>) {
            state.clientCode = action.payload;
        },
        setServerUris(state, action: PayloadAction<IServerURIs>) {
            state.serverUris = action.payload;
        }    

    }
})

export const { setPage,
    setLoading, 
    setConfigId,
    setNeedSetupRedirect, 
    addAttachments,
    clearAttachments, 
    removeAttachment, 
    initAppStateFromOrigin,
    setClientCode,
    setServerUris 
} = appSlice.actions;
export default appSlice.reducer