import { DTDataGridAlignment, DTDataGridColumnType, IDataColumn } from "../../models/IDataColumn";
import {IPackage} from "../../models/configuration/plugin/IConfiguration";

///TODO: Pending development due to expression data dependency
export const evaluationResultsColumns = (): Array<IDataColumn> => {
    return [
    {
        name: "Result",
        fieldId: "",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Control Type",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Id",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Message",
        fieldId: "",
        datatype: DTDataGridColumnType.text,
        readonly: true
    }
    ];
};

export const validDeliveryMethodsColumns = (): Array<IDataColumn> => {
    return [
    {
        name: "Enabled",
        fieldId: "Enabled",
        datatype: DTDataGridColumnType.checkbox,
        alignment: DTDataGridAlignment.center,
        readonly: true
    },
    {
        name: "Group",
        fieldId: "Group",
        datatype: DTDataGridColumnType.text,
        readonly: true
    },
    {
        name: "Package",
        fieldId: "Id",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        dataFunction: (pkg: IPackage) => {
            return pkg.ParentPackageId && (pkg.ParentPackageId === "" || pkg.ParentPackageId === undefined) ?
            pkg.Id :  pkg.AlternatePackageId + " " + pkg.Id
        }
    },
    {
        name: "Valid Delivery Types",
        fieldId: "ValidDeliveryTypes",
        datatype: DTDataGridColumnType.text,
        readonly: true,
        dataFunction: (pkg : IPackage) => {
            return pkg.ValidDeliveryTypes.toString()
        }
    }
    ];
};