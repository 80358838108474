import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appSlice from "./appSlice";
import originSlice from "./originSlice";
import { 
    configServersReducer, 
    configPlugInReducer, 
    configDocumentBundlesReducer, 
    configEventBundlesReducer,
    configDocumentMappingReducer,
    configPushbackReducer

} from "./configReducers";

const reducer = combineReducers({
    appSlice,
    originSlice,
    configServers: configServersReducer,
    configPlugInData: configPlugInReducer,
    configDocumentBundles: configDocumentBundlesReducer,
    configEventBundles: configEventBundlesReducer,
    configDocumentMapping: configDocumentMappingReducer,
    configPushbackDefaults: configPushbackReducer
})

export const store = configureStore({
    reducer
})

export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = AppStore['dispatch'];