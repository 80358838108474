import React from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';

interface GenericListSelectedProps {
  items: string[] | undefined;
  selectedItems: string[] | undefined;
  onItemClick: (item: string) => void;
}

const GenericListSelected: React.FC<GenericListSelectedProps> = ({ items, selectedItems, onItemClick, }) => {

  if (!items || items?.length === 0) {
    return <Typography>No items available</Typography>
  }

  return (
    <div>
        <List>
          {items?.map((item, index) => (
          <ListItemButton
              key={index}
              sx={{ color: selectedItems?.includes(item) ? 'red': 'blue' }}
              onClick={() => onItemClick(item)} 
          >
              <ListItemText primary={item} />
          </ListItemButton>
          ))}
      </List>
  </div>
  )
}

export default GenericListSelected;