import { useEffect, useCallback, useRef } from "react";
import { useActions } from "../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import EncompassService from "../services/encompassService";
import { useTypedSelector } from "./useTypedSelector";

export interface ConfigurationData {
    data: any | null;
    error: any;
    loading: boolean;
}

export enum ConfigurationDataType {
    OTHER_CONTROLS = "OtherControls",
    PACKAGES = "Packages",
    DELIVERY_TYPES = "DeliveryTypes",
    ALLOW_MULTI_DELIVERY = "AllowMultiDelivery",
    IMPERSONATION = "Impersonation",
    SEND_VALIDATIONS = "SendValidations",
    ERROR_MESSAGE_OVERRIDES = "ErrorMessageOverrides",
    DEFAULT_VALUES = "DefaultValues",
    DISCLOSURE_TRACKING_MAPPING = "DisclosureTrackingMapping"
}

function useConfigurationData(dataType: ConfigurationDataType | null) {
    const { getConfigPlugInData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configPlugInData);

    // Refs
    const hasFetched = useRef(false);

    useEffect(() => {
        if (!hasFetched.current) {
            const configId = EncompassService.getConfigId() as string;
            const delayFetch = setTimeout(() => {
                fetchData(configId);
                hasFetched.current = true;
            }, 1000);
            return () => clearTimeout(delayFetch); // Cleanup timeout
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = useCallback((configId: string) => {
        getConfigPlugInData(configId, accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getConfigPlugInData]);

    if (loading || !data) 
        return {
            data: null,
            error: null,
            loading: true
        } as ConfigurationData;

    let selectedData: any;
    switch (dataType) {
        case ConfigurationDataType.OTHER_CONTROLS:
            selectedData = data.Configuration?.OtherControls ?? null;
            break;
        case ConfigurationDataType.PACKAGES:
            selectedData = data.Configuration?.Packages ?? null;
            break;
        case ConfigurationDataType.DELIVERY_TYPES:
            selectedData = {
                DeliveryTypes: data.Configuration?.DeliveryTypes ?? null,
                AllowMultiDelivery: data.Configuration?.AllowMultiDelivery ?? null
            };
            break;
        case ConfigurationDataType.IMPERSONATION:
            selectedData = data.Configuration?.Impersonation ?? null;
            break;
        case ConfigurationDataType.SEND_VALIDATIONS:
            selectedData = data.Configuration?.SendValidations ?? null;
            break;
        case ConfigurationDataType.ERROR_MESSAGE_OVERRIDES:
            selectedData = data.Configuration?.ErrorMessageOverrides ?? null;
            break;
        case ConfigurationDataType.DEFAULT_VALUES:
            selectedData = data.Configuration?.DefaultValues ?? null;
            break;
        case ConfigurationDataType.DISCLOSURE_TRACKING_MAPPING:
            selectedData = data.Configuration?.DisclosureTrackingMapping ?? null;
            break;
        default:
            selectedData = data;
    }
    return {
        data: selectedData,
        error,
        loading
    } as ConfigurationData;
}

export { useConfigurationData };