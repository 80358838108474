import { useEffect, useCallback, useRef } from "react";
import { useActions } from "../hooks/useActions";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";
import EncompassService from "../services/encompassService";
import { useTypedSelector } from "./useTypedSelector";

// ! TODO: This loads in a lot of data, need to refactor for performance 
function useDocumentMappings() {

    const { getDocumentMappingData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);
    const { data, error, loading } = useTypedSelector((state) => state.configDocumentMapping);

    // Refs
    const hasFetched = useRef(false);
    const hasFetchedDocuments = useRef(false);

    useEffect(() => {

        if (!hasFetched.current) {
            const configId = EncompassService.getConfigId() as string;
            const delayFetch = setTimeout(() => {
                fetchDocuments(configId);
                hasFetchedDocuments.current = true;
            }, 1000);

            return () => clearTimeout(delayFetch); // Cleanup timeout
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchDocuments = useCallback((configId: string) => {
        getDocumentMappingData(configId, accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getDocumentMappingData]);

    if (loading || error || !hasFetchedDocuments) {
        return null;
    }

    return data;
}

export { useDocumentMappings };