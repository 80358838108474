import React from "react";
// @ts-ignore
// import host from '@elliemae/em-ssf-guest';
import { AppBar, IconButton, Stack, useTheme} from "@mui/material";
import { Close } from "@mui/icons-material";  
import ElliHost from "../services/elliHost";

const Header: React.FC = () => {
    const theme = useTheme();
    const closeEpcForm = async()=>{
        await ElliHost.getInstance().closeApplication();
    }
 
    return (
        <div>
        <AppBar sx={{ backgroundColor: theme.palette.secondary.main, height: "5rem"  }}>
            <Stack sx={{ padding: ".5rem 1rem" }} direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                <div className="logo"></div>
                <IconButton color="info"  onClick={closeEpcForm}   ><Close sx={{ color: "white" }} /></IconButton>
            </Stack>
        </AppBar> 
    </div>
    );
};

export default Header;