import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IDocumentMappingConfiguration } from "../../models/configuration/bundles/IDocumentMappings";

// This action creator gets the server config values from an api call or state
const saveDocumentMappings = (configId: string, docummentMappingConfig: IDocumentMappingConfiguration, token: string | null) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {
      
        dispatch({ type: ActionType.SAVE_DOCUMENT_MAPPING_DATA });
        
        try {
            
            if (!docummentMappingConfig.Configuration.DocumentMaps 
                || docummentMappingConfig.Configuration.DocumentMaps.length === 0) {
                throw new Error("No Document Map Data to Save");
            }

            await new PluginService().saveConfig<IDocumentMappingConfiguration>(token, "document-map", configId, docummentMappingConfig);
            
            dispatch({
                type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_SUCCESS,
                payload: docummentMappingConfig
            });
        
            return Promise.resolve();
        } catch (error: any) {
            console.error("Save Document Mapping Data");
            const payloadErrorMessage = `Failed to save other controls Data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_DOCUMENT_MAPPING_DATA_FAILURE,
                payload: payloadErrorMessage,
            });
            return Promise.reject(payloadErrorMessage);   
        }
    }
}

export default saveDocumentMappings;