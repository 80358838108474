import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import {
  IConfiguration,
  IPushbackConfiguration,
} from "../../models/configuration/pushback/IConfiguration";
import EncompassService from "../../services/encompassService";

// This action creator gets the server config values from an api call or state
const saveConfigPushbackData = (
  configId: string,
  updatedPushbackDefaultData: IConfiguration,
  token: string | null
) => {
  return async (dispatch: Dispatch<Action>, getState: () => any) => {
    dispatch({ type: ActionType.SAVE_CONFIG_PUSHBACK });

    try {
      
      const { data: pushbackConfiguration } = getState().configPushbackDefaults;
      if (!pushbackConfiguration) {
        throw new Error(" No PushbackConfiguration available in state to save");
      }

      if (!updatedPushbackDefaultData) {
        throw new Error("No Pushback Defaults to save");
      }

      const updatedConfig = {
        ...pushbackConfiguration.Configuration,
        EncompassInstanceId: EncompassService.getEncompassInstanceId(),
        ReceivedDatePopulation:
          updatedPushbackDefaultData.ReceivedDatePopulation,
        UnnamedDocumentTitle: updatedPushbackDefaultData.UnnamedDocumentTitle,
        MaxMessageRetryCount: updatedPushbackDefaultData.MaxMessageRetryCount,
        MessageRetryVisibilityTimeoutSeconds:
          updatedPushbackDefaultData.MessageRetryVisibilityTimeoutSeconds,
        LoanLockedVisibilityTimeoutSeconds:
          updatedPushbackDefaultData.LoanLockedVisibilityTimeoutSeconds,
        MaxConcurrency: updatedPushbackDefaultData.MaxConcurrency,
      };

      const requestBody = {
        ConfigurationId: configId,
        EncompassInstanceId: EncompassService.getEncompassInstanceId(),
        Configuration: updatedConfig,
      };

      await new PluginService().saveConfig<IPushbackConfiguration>(
        token,
        "pushback-processor",
        configId,
        requestBody
      );
      dispatch({
        type: ActionType.SAVE_CONFIG_PUSHBACK_SUCCESS,
        payload: { Configuration: updatedConfig },
      });

      return Promise.resolve();
    } catch (error: any) {
      console.error("Save Pushback Defaults Data");
      const payloadErrorMessage = `Failed to save Pushback Defaults Data: (${error.message})`;
      dispatch({
        type: ActionType.SAVE_CONFIG_PUSHBACK_FAILURE,
        payload: payloadErrorMessage,
      });
      return Promise.reject(payloadErrorMessage);
    }
  };
};

export default saveConfigPushbackData;