// create action creator for saving config message data
import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IDisclosureTrackingMapping, IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";
import EncompassService from "../../services/encompassService";

const saveConfigDisclosureTrackingMappingData = (configId: string, updatedDisclosureTrackingMapping: IDisclosureTrackingMapping | null, token: string | null) => {
    
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.SAVE_CONFIG_PLUGIN_DATA });
        
        try {

            const { data: pluginConfiguration } = getState().configPlugInData;
            
            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }
                
            if (!updatedDisclosureTrackingMapping) {
                throw new Error("No Disclosure Tracking Mappings to save");
            }

            // Using the Plug In State Data update the Disclosure Tracking Mappings
            const updatedConfig = {
                ...pluginConfiguration.Configuration,
                DisclosureTrackingMapping: updatedDisclosureTrackingMapping
            };

            const requestBody = {
                "ConfigurationId": configId,
                "EncompassInstanceId": EncompassService.getEncompassInstanceId(),
                "Configuration": updatedConfig
            };
            
            await new PluginService().saveConfig<IPluginConfiguration>(token, "plugin", configId, requestBody);

            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

            return Promise.resolve();

        } catch (error: any) {
            const payloadErrorMessage = `Failed to save Messages data: (${error.message})`;
            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE,
                payload: payloadErrorMessage,
            });

            return Promise.reject(payloadErrorMessage);
        }
    }
}

export default saveConfigDisclosureTrackingMappingData;