import React from 'react';
import { Card, CardContent, List, ListItemButton, ListItemText } from '@mui/material';
import { IDeliveryType } from '../../../models/configuration/plugin/IConfiguration';

interface DeliveryTypesListProps {
    deliveryTypes: IDeliveryType[] | null,
    selectedDeliveryTypeIdx: string | null,
    selectDeliveryType: (id: string) => void,
}

const DeliveryTypesList: React.FC<DeliveryTypesListProps> =
    ({ deliveryTypes, selectedDeliveryTypeIdx, selectDeliveryType }) => {

        return (

            // I want the card to overflow and scroll if the content is too large
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, maxHeight: "31rem", overflowY: 'auto' }}>
                <CardContent>
                    {deliveryTypes && deliveryTypes.length === 0 && <div>No delivery types found</div>}
                    {deliveryTypes && deliveryTypes.length > 0 && (
                      <List>
                        {deliveryTypes?.map((dt) => (
                            <ListItemButton
                                key={dt.Id}
                                sx={{ color: dt.Enabled ? 'blue' : 'grey' }}
                                selected={selectedDeliveryTypeIdx === dt.Id}
                                onClick={() => selectDeliveryType(dt.Id)}
                            >
                                <ListItemText primary={dt.Id} />
                            </ListItemButton>
                        ))}
                    </List>
                    )}
                </CardContent>
            </Card>
        );
    }

export default DeliveryTypesList;