import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../state/store";


const PageLoad: React.FC = () => {
    const appState = useSelector((state: RootState) => state.appSlice)
    const theme = useTheme();
    return (
        <Backdrop
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "rgba(0, 0, 0, 0.5)" }}
            open={appState.loading}
        >
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor={theme.palette.secondary.main} />
                        <stop offset="100%" stopColor={theme.palette.secondary.main} />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </Backdrop>

    )
}

export default PageLoad