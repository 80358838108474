import { Card, CardContent, Tabs, Tab, Box } from "@mui/material";
import { useState } from "react";
import DtTab from "./DtTab";

const LogTabs = () => {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }


    return (
        <Card>
            <CardContent sx={{ padding: 0 }}>
                <Tabs value={tabValue} onChange={handleTabChange}  >
                    <Tab label="Pushback" />
                    <Tab label="Client Logs" />
                    <Tab label="Form Log" />
                </Tabs>
                <DtTab value={tabValue} index={0}>
                    <Box>
                        Pushback Log Placeholder
                    </Box>
                </DtTab>
                <DtTab value={tabValue} index={1}>
                    <Box>
                        Client Log Placeholder
                    </Box>
                </DtTab>
                <DtTab value={tabValue} index={2}>
                    <Box>
                        Form Log Placeholder
                    </Box>
                </DtTab>
            </CardContent>
        </Card>
    )
}

export default LogTabs;