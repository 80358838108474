import ApiService from "./apiService";
import ElliHost from "./elliHost";
import { setOriginVals } from "../state/originSlice";
import { initAppStateFromOrigin } from "../state/appSlice";
import { store } from "../state/store"
import { AxiosResponse } from "axios";
import { IInitAppState } from "../models/IInitAppState";

const baseURL = process.env.REACT_APP_EPC_API as string; // ToDo: this should be fetched from the config

class OriginService {

    private apiService: ApiService;
    private elliHost;

    constructor() {
        this.apiService = new ApiService(baseURL);
        this.elliHost = ElliHost.getInstance();
    }

    private async getOriginApi(elliPat: string, originId: string): Promise<AxiosResponse<unknown, any>> {
        const apiOrigin = await this.apiService.get(`origins/${originId}`, {
            headers: {
                "X-Eli-Pat": elliPat
            }
        });
        return apiOrigin;
    }

    public async buildOrigin(): Promise<any> {
        const host: any = await this.elliHost.getOrigin();
        store.dispatch(setOriginVals({ pat: host.partnerAccessToken, id: host.id }))
        const origin: any = await this.getOriginApi(host.partnerAccessToken, host.id)
        const roles = await this.elliHost.getRoles();
        store.dispatch(initAppStateFromOrigin({
            authToken: origin?.data?.result?.oAuthToken?.accessToken as string ?? null,
            authExpires: origin?.data?.result?.oAuthToken?.expirationDateTimeUTC as string ?? null,
            configId: origin?.data?.result?.configId as string ?? null,
            personas: origin?.data?.result?.pointOfContact?.personas as Array<string> ?? [],
            loanID: origin?.data?.result?.loan?.id as string ?? null,
            encompassRoles: roles ?? [],
            userId: origin?.data?.result?.pointOfContact?.email as string ?? null,
            userFullName: origin?.data?.result?.pointOfContact?.name as string ?? null
        } as IInitAppState))

        return origin;
    }

};

export default OriginService;


