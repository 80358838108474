import { createTheme } from "@mui/material";

const colorTheme = createTheme({
    typography: {
        fontFamily: [  
          'Roboto', 
          'sans-serif'
        ].join(','),
      },
    palette:{
        primary:{
            main:'#5693F7',
            contrastText:'#fff'
        },
        secondary:{
            main:'#143A6B',
            contrastText:'#fff'
        },
        success:{
            main:'#4AB15F',
            contrastText:'#fff'
        },
        warning:{
            main:'#E68231',
            contrastText:'#fff'
        },
        error:{
            main:'#C33E38',
            contrastText:'#fff'
        }
    }
})

export default colorTheme;