import React, { useState } from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';

interface GenericListProps {
  items: string[] | undefined;
  onSelected: (itemSelected: string | null) => void;
}

const GenericList: React.FC<GenericListProps> = ({ items, onSelected }) => {

  const [selectedIndex, setSelectedIndex] = useState<Number | null>(null)

  const handleClick = (event: React.MouseEvent, index: number, item: string) => {
    event.stopPropagation();
    setSelectedIndex(index);
    onSelected(item);
  }

  const handleListClick = () => {
    setSelectedIndex(null);
    onSelected(null);
  }

  if (items?.length === 0) {
    return <Typography>No items available</Typography>
  }

  return (
    <List onClick={handleListClick}
      sx={{ height: "250px", overflow: "auto" }}>
      {items?.map((item, index) => (
        <ListItemButton
          key={index}
          className='MuiListItemButton-root'
          sx={{
            color: 'blue',
            backgroundColor: selectedIndex === index ? 'lightgray' : 'transparent'
          }}
          onClick={(event) => handleClick(event, index, item)}
        >
          <ListItemText primary={item} />
        </ListItemButton>
      ))}
    </List>
  )
}

export default GenericList;