import { Box } from "@mui/material";
import DtTabPanelProps from "../../models/DtTabPanelProps";

const DtTab =(props: DtTabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel"
            hidden={value !== index}
            id={`tab-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    )
}
 
export default DtTab