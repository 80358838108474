import { Dispatch } from "redux";
import { Action, ActionType } from "../actions";
import { IImpersonation } from "../../models/configuration/plugin/IConfiguration";

const saveConfigImpersonationData = (impersonationData: IImpersonation) => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.SAVE_CONFIG_PLUGIN_DATA });

        try {
      
            const { data: pluginConfiguration } = getState().configPlugInData;

            if (!pluginConfiguration) {
                throw new Error(" No PluginConfiguration available in state to save");
            }

            const updatedConfig = {
                ...pluginConfiguration.Configuration,
                Impersonation: impersonationData
            };

            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: { "Configuration": updatedConfig }
            });

            return Promise.resolve();

        } catch (error: any) {
            dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_FAILURE,
                payload: error.message
            });
        }
    };
};

export default saveConfigImpersonationData;