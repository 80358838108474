import React from 'react';
import { Card, CardContent, List, ListItemButton, ListItemText } from '@mui/material';
import { IPackage } from '../../../models/configuration/plugin/IConfiguration';

interface PackagesListProps {
    packages: IPackage[] | null,
    selectedPackageIdx: number | null,
    selectPackage: (index: number, id: string) => void,
}

const PackagesList: React.FC<PackagesListProps> =
    ({ packages, selectedPackageIdx, selectPackage }) => {

        return (

            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2 }}>
                <CardContent>
                    {packages && packages.length === 0 && <div>No packages found</div>}
                    {packages && packages.length > 0 && (
                        <List>
                            {packages?.map((p, index) => (
                                <ListItemButton
                                    key={index}
                                    sx={{ color: p.Enabled ? 'blue' : 'grey' }}
                                    selected={selectedPackageIdx === index}
                                    onClick={() => selectPackage(index, p.Id as string)}
                                >
                                    <ListItemText primary={p.Id} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                </CardContent>
            </Card>
        );
    }

export default PackagesList;