import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Tab, Tabs } from '@mui/material';
import CustomTabPanel from '../../shared/CustomTabPanel';
import { IExpression, IDeliveryType, ICustomAttributes } from '../../../models/configuration/plugin/IConfiguration';
import ExpressionsControl from '../../shared/config/ExpressionsControl';
import CustomAttributes from '../../shared/config/CustomAttributes';

interface deliveryTypesTabProps {
    tabIndex: number,
    selectedDeliveryType: IDeliveryType | null;
    updateParent: (updatedDeliveryType: IDeliveryType) => void;
    children?: React.ReactNode
}

const DeliveryTypesTab: React.FC<deliveryTypesTabProps> = ({ selectedDeliveryType, updateParent }) => {

    const [selectedDeliveryTypeState, setSelectedDeliveryTypeState] = useState<IDeliveryType | null>(selectedDeliveryType);
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    // Selected Package Changed
    useEffect(() => {
        if (selectedDeliveryType)
            setSelectedDeliveryTypeState(selectedDeliveryType);
    }, [selectedDeliveryType]);

    /* Expressions */
    const updateExpressions = (updatedExpressions: IExpression[]) => {
        if (selectedDeliveryTypeState && selectedDeliveryTypeState.Expressions) {
            const newDeliveryType = { ...selectedDeliveryTypeState, Expressions: updatedExpressions };
            setSelectedDeliveryTypeState(newDeliveryType);
            updateParent(newDeliveryType);
        }
    }

    const updateCustomAttributes = (updatedCustomAttributes: ICustomAttributes) => {
        if (selectedDeliveryTypeState) {
            const newDeliveryType = { ...selectedDeliveryTypeState, CustomAttributes: updatedCustomAttributes };
            setSelectedDeliveryTypeState(newDeliveryType);
            updateParent(newDeliveryType);
        }
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (

        <div>
            <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2 }}>
                <CardContent>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Expressions" {...a11yProps(0)} />
                        <Tab label="Attributes" {...a11yProps(1)} />

                    </Tabs>
                    <CustomTabPanel value={tabValue} index={0}>
                        <ExpressionsControl
                            expressions={selectedDeliveryTypeState?.Expressions}
                            updateParentExpressions={updateExpressions}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={tabValue} index={1}>
                        <Box sx={{ p: 3 }}>
                            <div>
                                <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, height: '400px' }}>
                                    <CustomAttributes
                                        customAttributes={selectedDeliveryTypeState?.CustomAttributes}
                                        updateParent={updateCustomAttributes}
                                    />
                                </Card>
                            </div>
                        </Box>


                    </CustomTabPanel>
                </CardContent>
            </Card>
        </div>
    )
}

export default DeliveryTypesTab;