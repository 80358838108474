import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField } from '@mui/material';

interface InputEmailsProps {
    open: boolean;
    title: string;
    submitButtonText?: string;
    onClose: any;
    setEmails: any;
    emails: string;
    text: string;
    error: boolean;
}

const InputEmails: React.FC<InputEmailsProps> = ({
    open,
    title,
    submitButtonText = "Ok",
    onClose,
    emails,
    setEmails,
    text = "Delivery requires emails. Please enter below, separating emails by a comma (,).",
    error=false 
}) => {
    return (
        <Dialog open={open} fullWidth maxWidth="md">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, height: '100%' }}>
                    {text}
                    <TextField multiline error={error} value={emails} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmails(event.target.value);
                    }} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{submitButtonText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InputEmails;