import { GenericCardProps } from "../models/GenericCardProps"; 
import { Card, CardHeader, CardContent, List, CardActions, Button } from "@mui/material";

function SelectToPrintCard(props: GenericCardProps) {

    const cardVisibility = ()=>{
        if(props.visible)
            return 'flex';
        return 'none';
    }
 
    return (
        <Card sx={{ p: 2, display: cardVisibility, flexDirection: 'column' }}>
            <CardHeader title={props.title ?? "Select"}>
            </CardHeader>
            <CardContent sx={{ overflow: 'auto', paddingTop: "0px", marginTop: "-15px" }}>
                <List>
                </List>
            </CardContent>
            <CardActions sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
            }}>
                <Button variant="contained" size="small" disabled={!props.enabled}>Select to Print</Button>
                <Button variant="contained" size="small" color="error" disabled={!props.enabled}>Clear</Button>
            </CardActions>
        </Card>
    )
}

export default SelectToPrintCard;