export interface ExpressionValidationResult {
    isValid: boolean;
    errors: string[];
}



/* 
    Im not sure this will work for validation of expressions.
    The existing expressions are in VB Syntax and this is a JS function.
    For now I will just implement the function and see if it works.
    If it does not work then I will have to implement a function that can handle VB syntax.
    Which may be a server side call 
*/
const validateExpression = (expression: string): ExpressionValidationResult => {
    const errors: string[] = [];

    // Variable regex: handles variables like [4002#1], [1234], etc.
    const variableRegex = /\[[a-zA-Z0-9.@#]+\]/g;
    const variables = expression.match(variableRegex);
    
    if (!variables || variables.length === 0) {
        errors.push("No variables found in the format [Var] or [Var#Index].");
    }

    // Match parentheses
    const openParentheses = (expression.match(/\(/g) || []).length;
    const closeParentheses = (expression.match(/\)/g) || []).length;
    if (openParentheses !== closeParentheses) {
        errors.push("Mismatched parentheses.");
    }

    // Ignore the `#` symbol in variables for date validation
    const nonVariablePart = expression.replace(variableRegex, ''); // Remove variable parts to check the rest of the string for dates
    const dateRegex = /#\d{2}\/\d{2}\/\d{4}#/;
    if (nonVariablePart.includes("#") && !dateRegex.test(nonVariablePart)) {
        errors.push("Invalid date format. Should be in #MM/DD/YYYY# format.");
    }

    // Check for logical operators only if there are multiple conditions
    const logicalOperatorsRegex = /\b(AND|OR|Not|AndAlso)\b/g; // Probably need to add here
    const logicalOperators = expression.match(logicalOperatorsRegex);
    if (logicalOperators && logicalOperators.length > 0 && expression.split(logicalOperatorsRegex).length > 2) {
        if (!logicalOperators || logicalOperators.length === 0) {
            errors.push("Missing logical operators (AND, OR, Not, AndAlso).");
        }
    }

    // Check for valid function calls and capture incorrect spellings
    // Will need to add here once scope is found out 
    const validFunctionRegex = /\b(IsDate|string\.IsNullOrWhiteSpace)\s*\(\s*\[[^\]]+\]\s*\)/g;
    const possibleFunctionsRegex = /\b([a-zA-Z]+)\s*\(\s*\[[^\]]+\]\s*\)/g;
    const functionMatches = expression.match(possibleFunctionsRegex);

    if (functionMatches && functionMatches.length > 0) {
        functionMatches.forEach((fn) => {
            if (!validFunctionRegex.test(fn)) {
                errors.push(`Invalid or misspelled function: ${fn}. Expected 'IsDate' or 'string.IsNullOrWhiteSpace'.`);
            }
        });
    }

    return {
        isValid: errors.length === 0,
        errors,
    };
};

export { validateExpression };