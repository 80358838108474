import { Dispatch } from "react";
import { Action, ActionType } from "../actions";
import PluginService from "../../services/pluginService";
import { IPluginConfiguration } from "../../models/configuration/plugin/IConfiguration";

const getConfigPlugInData = (configId: string, token: string | null): (dispatch: Dispatch<Action>, getState: () => any) => Promise<void> => {
    return async (dispatch: Dispatch<Action>, getState: () => any) => {

        dispatch({ type: ActionType.GET_CONFIG_PLUGIN_DATA });

        try {

            const plugInData = getState().configPlugInData.data;

            if (plugInData !== null) { // Checking for exiting state data  
                dispatch({
                    type: ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS,
                    payload: plugInData
                });
                return;
            } else {
                const payload = await new PluginService().getConfig<IPluginConfiguration>(token, "plugin", configId);
                
                dispatch({
                    type: ActionType.GET_CONFIG_PLUGIN_DATA_SUCCESS,
                    payload: payload
                });
            }

        } catch (error: any) {
            console.error("Get Config Plugin Data");
            if (error instanceof Error) {
                const payloadErrorMessage = `Failed to fetch Config Plugin Data: (${error.message})`;
                dispatch({
                    type: ActionType.GET_CONFIG_PLUGIN_DATA_FAILURE,
                    payload: payloadErrorMessage,
                });
            }
        }
    }
}

export default getConfigPlugInData;