import React, { useEffect, useState } from 'react';
import { Card, CardContent, TextField, Box, Typography } from '@mui/material';
import { ICustomAttributes } from '../../../models/configuration/plugin/IConfiguration';

const initialCustomAttributesState: ICustomAttributes = {
    IframeVendor: null,
    IPENVendor: null,
    VerificationVendorType: null,
    FormFreeLenderID: null,
};

interface CustomAttributesProps {
    customAttributes: ICustomAttributes | undefined;
    updateParent: (attributes: ICustomAttributes) => void;
}

const CustomAttributes: React.FC<CustomAttributesProps> = ({ customAttributes, updateParent }) => {
    const [customAttributesState, setCustomAttributesState] = useState<ICustomAttributes>(initialCustomAttributesState);

    // Set the initial state if customAttributes are present
    useEffect(() => {
        if (customAttributes) {
            setCustomAttributesState(customAttributes);
        }
    }, [customAttributes]);

    const handleInputChange = (key: keyof ICustomAttributes) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const updatedState = { ...customAttributesState, [key]: event.target.value };
        setCustomAttributesState(updatedState);
        updateParent(updatedState);
    };

    function updateFriendly(key: string): React.ReactNode {
        switch(key) {
            case 'IframeVendor':
                return 'Iframe Vendor';
            case 'IPENVendor':
                return 'IPEN Vendor';
            case 'VerificationVendorType':
                return 'Verification of Information Vendor Type';
            case 'FormFreeLenderID':
                return 'Freeform Vendor ID';    
        }
    }

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', margin: 2, width: "100%" }}>
            <CardContent>
                {Object.keys(customAttributesState).map((key) => (
                    <Box key={key} sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                        <Typography sx={{ flex: 1, marginRight: ".2rem" }}>{updateFriendly(key)}:</Typography>
                        <TextField
                            sx={{ flex: 2 }}
                            value={customAttributesState[key as keyof ICustomAttributes] || ''}
                            onChange={handleInputChange(key as keyof ICustomAttributes)}
                        />
                    </Box>
                ))}
            </CardContent>
        </Card>
    );
};

export default CustomAttributes;