import ReactDOM from "react-dom/client"
import React from "react";
import App from "./components/App";
import "./assets/style.css"
import {store} from "./state/store";
import { Provider } from 'react-redux';

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <Provider store={store}>
                <App />
        </Provider>
    </React.StrictMode>
)