import React from 'react';
import { Snackbar, Alert } from '@mui/material';

interface AlertSnackbarProps {
    alertOpen: boolean;
    alertMessage: string;
    alertSeverity: 'success' | 'error' | 'info' | 'warning';
    handleSnackBarClose: () => void;
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({ alertOpen, alertMessage, alertSeverity, handleSnackBarClose }) => {
    return (
        <Snackbar 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={alertOpen} 
            autoHideDuration={6000} 
            onClose={handleSnackBarClose}>
            <Alert onClose={handleSnackBarClose} severity={alertSeverity} sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
    );
};

export default AlertSnackbar;