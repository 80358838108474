import React, { useEffect, useMemo, useRef, useState } from "react";
import MuiAlert from '@mui/material/Alert';
import { Box, SelectChangeEvent, Typography, Card, CardContent, Button, Snackbar, Select, MenuItem, Autocomplete, TextField } from "@mui/material";
import { useActions } from "../../hooks/useActions";
import "../../assets/style.css";
import ConfigLoad from "../../components/shared/config/ConfigLoad";
import PackagesList from "../../components/configuration/_packages/PackagesList";
import PackageDetails from "../../components/configuration/_packages/PackageDetails";
import PackagesTab from "../../components/configuration/_packages/PackagesTab";
import EncompassService from "../../services/encompassService";
import { IPackage } from "../../models/configuration/plugin/IConfiguration";
import { RootState, store } from "../../state/store";
import { useSelector } from "react-redux";
import ConfirmationDialog from "../../components/shared/ConfirmDialog";
import ConfigBottomBar from "../../components/shared/config/ConfigBottomBar";
import ConfigTopBar from "../../components/shared/config/ConfigTopBar";
import PackageGroupRename from "../../components/configuration/_packages/PackageGroupRename";
import { ConfigurationDataType, useConfigurationData } from "../../hooks/useConfigurationData";
import { ActionType } from "../../state/actions";

const ConfigPackages: React.FC = () => {

    const { saveConfigPackagesData } = useActions();
    const accessToken = useSelector((state: RootState) => state.appSlice.accessToken);

    // Package State 
    const [packages, setPackages] = useState<IPackage[]>([]);
    const [packageList, setPackageList] = useState<IPackage[]>([]);
    const [packagesDropDown, setPackagesDropDown] = useState<string[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<string | undefined>('');
    const [selectedPackage, setSelectedPackage] = useState<IPackage | null>(null);
    const [selectedPackageIdx, setSelectedPackageIdx] = useState<number | null>(null);
    const [selectedConfigId, setSelectedConfigId] = useState<string>('');

    // Sub States
    const [tabIndex] = useState<number>(1);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [confirmDeleteTitle] = useState('Delete Package');
    const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('Are you sure you want to delete this package?');
    const [selectedGroupToReassign, setSelectedGroupToReassign] = useState<string | undefined>('');
    const [openReassignGroup, setOpenReassignGroup] = useState<boolean>(false);
    const [openPackageId, setOpenPackageId] = useState<boolean>(false);
    const [selectedNewPackageId, setSelectedNewPackageId] = useState<string | undefined>('');
    const [openPackageGroupRename, setOpenPackageGroupRename] = useState<boolean>(false);

    // State Confirmations
    const [alertOpen, setAlertOpen] = useState(false);
    const [saveError, setSaveError] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity, setAlertSeverity] = useState<'success' | 'error' | 'info' | 'warning'>('info');
    const [saving, setSaving] = useState<boolean>(false);

    // Loading Data
    const hasFetchedData = useRef(false);
    const { data, error, loading } = useConfigurationData(ConfigurationDataType.PACKAGES);
    const mData = useMemo(() => data, [data]);

    useEffect(() => {
        if (!hasFetchedData.current && data) {
            const configId = EncompassService.getConfigId() as string;
            setSelectedConfigId(configId);
            setPackages(mData);
            hasFetchedData.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    // Handling Loading Error 
    useEffect(() => {
        if (!error) {
            setErrorMessage(error);
        }
    }, [error]);

    // Handling Saving Error 
    useEffect(() => {
        if (!saveError && data !== null) {
            setPackages(mData); // Only update local data if there is no save Error condition 
        }
    }, [data, saveError, mData]);

    // Packages changed
    useEffect(() => {
        if (packages && packages.length > 0) {
            updatePackagesDropDown(packages);
            saveState(); //
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packages]);

    const handleGroupChange = (event: SelectChangeEvent<string>) => { setSelectedGroup(event.target.value as string); }

    const updatePackagesDropDown = (packages: IPackage[]) => {
        const uniqueGroups = Array.from(new Set(packages.map((p) => p.Group)));
        setPackagesDropDown(uniqueGroups);
    }

    // Group Changed
    useEffect(() => {
        if (selectedGroup && packages) {
            const updatedPackageList = packages.filter((p) => p.Group === selectedGroup);
            setPackageList(updatedPackageList);
        }
    }, [selectedGroup, packages]);

    // New Package Selected 
    const selectPackage = (index: number, id: string) => {
        setSelectedPackageIdx(index);
        setSelectedPackage(packageList.find(p => p.Id === id) || null);
    }

    // Package Updated 
    useEffect(() => {
        if (selectedPackage != null && packages != null && packages.length > 0) {
            const existingPackage = packages.find((p) => p.Id === selectedPackage.Id);
            if (existingPackage && existingPackage !== selectedPackage) {
                const updatedPackageList = packages.map((p) => p.Id === selectedPackage.Id ? selectedPackage : p);
                refreshLists(updatedPackageList, selectedGroup);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPackage, packages, selectedGroup]);

    // Package Property Changes
    const handleEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Enabled", event.target.checked); };
    const handleOrderChange = (event: SelectChangeEvent) => { handleControlPropChange("Order", event.target.value); };
    const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("Caption", event.target.value); };
    const handleToolTipChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("ToolTipText", event.target.value); };
    const handleAutoSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => { handleControlPropChange("AutoSelectWhen", event.target.value); }
    const handleControlPropChange = (property: keyof IPackage, value: any) => {
        if (selectedPackage) {
            const updatePackage = { ...selectedPackage, [property]: value };
            setSelectedPackage(updatePackage);
        }
    }

    const updateSelectedPackage = (updatedControl: IPackage) => { setSelectedPackage(updatedControl); }
    const onUpdateUsers = (users: string[]) => {
        setSelectedPackage(
            selectedPackage ? { ...selectedPackage, Users: [...users] } : null,
        )
    }

    // *** DELETE HANDLING ***
    const DeletePackage = () => {
        setConfirmDeleteMessage(`Are you sure you want to delete package: '${selectedPackage?.Id}' ?`);
        setOpenDeleteConfirm(true);
    }

    const handleConfirmDelete = () => {
        setOpenDeleteConfirm(false);
        if (selectedPackage) {
            const updatedPackages = packages.filter((p) => p.Id !== selectedPackage.Id);
            setPackages(updatedPackages);
            setPackageList(updatedPackages.filter((p) => p.Group === selectedGroup));
            setSelectedPackage(null);
        }
    }

    const handleConfirmDeleteCancel = () => { setOpenDeleteConfirm(false); }

    // *** GROUP RE-ASSIGNMENT HANDLING ***
    const ReAssignGroup = () => { setOpenReassignGroup(!openReassignGroup); }
    const handleGroupReassignment = (event: React.SyntheticEvent, newValue: string | null) => { setSelectedGroupToReassign(newValue || ""); }
    const handleGroupReassignmentInputChange = (event: React.SyntheticEvent, newInputValue: string) => { setSelectedGroupToReassign(newInputValue); }

    const ReAssignGroupSubmit = () => {
        if (selectedPackage && selectedGroupToReassign) {
            const updatedPackage = { ...selectedPackage, Group: selectedGroupToReassign };
            const updatedPackages = packages.map((p) => p.Id === selectedPackage.Id ? updatedPackage : p);
            refreshLists(updatedPackages, selectedGroupToReassign);
            setSelectedPackage(updatedPackage);
        }
        setOpenReassignGroup(false);
        openAlert(`Successfully Re-Assigned to Group: ${selectedGroupToReassign}`, 'success');
    }

    // *** CHANGE PACKAGE ID HANDLING ***
    const updatePackageId = () => { setOpenPackageId(!openPackageId); }
    const handleNewPackageIdAssignment = (newPackageId: string) => { setSelectedNewPackageId(newPackageId); }
    const UpdatePackageIdSubmit = () => {
        if (selectedPackage && selectedNewPackageId) {
            const updatedPackage = { ...selectedPackage, Id: selectedNewPackageId };
            const updatedPackages = packages.map((p) => p.Id === selectedPackage.Id ? updatedPackage : p);
            refreshLists(updatedPackages, selectedGroup);
            setSelectedPackage(updatedPackage);
            setOpenPackageId(false);
            openAlert(`Successfully Updated Package ID: ${selectedNewPackageId}`, 'success');
        } else {
            openAlert("No Package Selected or New Package ID is empty", 'error');
        }
    }

    // Update Package List
    const refreshLists = (updatePackages: IPackage[], group: string | undefined) => {
        setPackages(updatePackages);
        setPackageList(updatePackages.filter((p) => p.Group === group));
        updatePackagesDropDown(updatePackages);
    }

    // Call this to save local session redux state
    const saveState = () => {
        try {
            if (packages.length === 0) return;
            const currentState = store.getState();
            const configPlugInData = currentState.configPlugInData.data;
            const updatedConfigPlugInData = {
                ...configPlugInData,
                Configuration: {
                    ...configPlugInData?.Configuration,
                    Packages: packages,
                },
            };
            store.dispatch({
                type: ActionType.SAVE_CONFIG_PLUGIN_DATA_SUCCESS,
                payload: updatedConfigPlugInData
            });

        } catch (e) {
            console.log("Error Saving State: ", e);
            openAlert(`Error Saving State: ${e}`, 'error');
        }
    }

    const save = async () => {
        try {
            setSaving(true);
            const configId = selectedConfigId || EncompassService.getConfigId() as string;
            await saveConfigPackagesData(configId, [...packages], accessToken);
            openAlert("Successfully Saved Changes", 'success');
            setSaveError(false);
            setErrorMessage('');
        } catch (e) {
            const error = `${e}`;
            console.log("Save Error: ", error);
            setSaveError(true);
            setErrorMessage(error);
        } finally {
            setSaving(false);
        }
    }

    function RefreshScreen(): void {
        openAlert(`Not yet implemented`, 'info');
    }

    // *** RENAME GROUP HANDLING  ***
    const RenameGroup = () => { setOpenPackageGroupRename(true); }
    const closePackageGroupRename = () => { setOpenPackageGroupRename(false); }
    const submitPackageGroupRename = (newGroupName: string) => {
        if (selectedGroup === newGroupName) {
            openAlert(`New name '${newGroupName}' is the same as the existing name, please try a different name`, 'info');
            return;
        }

        if (selectedGroup && newGroupName) {
            const updatedPackages = packages.map((pkg) =>
                pkg.Group === selectedGroup ? { ...pkg, Group: newGroupName } : pkg
            );
            refreshLists(updatedPackages, newGroupName);
            setSelectedGroup(newGroupName);
            openAlert(`Successfully renamed group to: '${newGroupName}'`, 'success');
        }
        setOpenPackageGroupRename(false);
    }

    // Alert Handling 
    const handleSnackBarClose = (_event: any) => { setAlertOpen(false); }
    const openAlert = (message: string, severity: 'success' | 'error' | 'info' | 'warning' = 'info') => {
        setAlertSeverity(severity);
        setAlertMessage(message);
        setAlertOpen(true);
    }

    return (
        <>
            <section>
                <div>
                    {(loading || saving) && <ConfigLoad loading={loading} />}
                </div>
                <div>

                    <Box
                        mt={1} component="main" sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1, overflow: "auto", minHeight: '100vh', margin: "2px"
                        }}
                    >

                        <ConfigTopBar save={save} error={error || errorMessage || null} />

                        <div className="configPackageContainer">
                            {/* Left Side */}
                            <div className="configPackageSubContainer-Left">
                                <Box sx={{ margin: 5 }}>
                                    <Card sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent>
                                            <Box sx={{
                                                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 2,
                                                alignItems: 'center', marginBottom: 2

                                            }}>
                                                <Button disabled={!selectedGroup} onClick={() => RefreshScreen()} variant="contained" size="small" >Refresh</Button>
                                                <Button disabled={!selectedGroup} onClick={() => RenameGroup()} variant="contained" size="small" >Rename</Button>
                                                <PackageGroupRename
                                                    open={openPackageGroupRename}
                                                    onClose={closePackageGroupRename}
                                                    onSubmit={submitPackageGroupRename}
                                                    existingName={selectedGroup || ""}
                                                />

                                            </Box>

                                            <Typography fontSize={14}>Select a group</Typography>

                                            <Select
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                value={selectedGroup}
                                                onChange={handleGroupChange}
                                                label="Select Group"
                                            >
                                                {packagesDropDown.map((group, index) => (
                                                    <MenuItem key={index} value={group}>
                                                        {group}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </CardContent>
                                    </Card>
                                </Box>
                                <Box>
                                    <PackagesList
                                        packages={packageList}
                                        selectedPackageIdx={selectedPackageIdx}
                                        selectPackage={selectPackage}
                                    />

                                    {selectedPackage && (
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, margin: 5 }}>

                                            {/* ReAssign Group */}
                                            {openReassignGroup && (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: "1px dashed grey", padding: "10px" }}>
                                                    <Typography fontSize={14}>Package Re-Assign to a Group</Typography>
                                                    <Autocomplete value={selectedGroupToReassign} onChange={handleGroupReassignment} onInputChange={handleGroupReassignmentInputChange} options={packagesDropDown}
                                                        renderInput={(params) => (<TextField {...params} label="Select Group to Re-Assign" variant="outlined" size="small" fullWidth />)}
                                                        freeSolo // Free Solo allows free typing 
                                                    />
                                                    <Button onClick={() => ReAssignGroupSubmit()} variant="contained" size="small" disabled={!selectedGroupToReassign} >Submit</Button>
                                                </Box>
                                            )}

                                            <Button onClick={() => ReAssignGroup()} variant="contained" size="small" >Re-Assign Group</Button>

                                            {/* Update Package ID */}
                                            {openPackageId && (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, border: "1px dashed grey", padding: "10px" }}>
                                                    <Typography fontSize={14}>Package Re-Assign to a Group</Typography>
                                                    <Typography variant="subtitle1" sx={{ margin: "5px" }}>From: {selectedPackage.Id}</Typography>
                                                    <div style={{ display: 'flex', flexDirection: "row", justifyContent: "flex-start" }}>
                                                        <Typography variant="subtitle1" sx={{ margin: "5px" }}>To: </Typography>
                                                        <TextField value={selectedNewPackageId} onChange={(e) => handleNewPackageIdAssignment(e.target.value)} sx={{ flex: 1 }} fullWidth variant="outlined" size="small" />
                                                    </div>
                                                    <Button onClick={() => UpdatePackageIdSubmit()} variant="contained" size="small" disabled={!selectedNewPackageId} >Submit</Button>
                                                </Box>
                                            )}

                                            <Button onClick={() => updatePackageId()} variant="contained" size="small" >Update PackageID</Button>
                                            <Button onClick={() => DeletePackage()} variant="contained" size="small" color="error" >Delete</Button>
                                        </Box>
                                    )}
                                </Box>

                            </div>
                            <div className="configPackageSubContainerBottom">
                                {/* Right Side */}
                                <div className="configPackageSubContainer-Right"
                                    style={{ pointerEvents: selectedPackage ? 'auto' : 'none', opacity: selectedPackage ? 1 : 0.5 }}>
                                    <Box sx={{ margin: 5 }}>
                                        <PackageDetails
                                            selectedPackage={selectedPackage}
                                            handleOnEnabledChange={handleEnabledChange}
                                            handleOnOrderChange={handleOrderChange}
                                            handleOnCaptionChange={handleCaptionChange}
                                            handleOnToolTipTextChange={handleToolTipChange}
                                            handleOnAutoSelectChange={handleAutoSelectChange}
                                        />
                                    </Box>
                                    <Box>
                                        <PackagesTab
                                            tabIndex={tabIndex}
                                            selectedPackage={selectedPackage}
                                            updateParentPackage={updateSelectedPackage}
                                            sendUserUpdate={onUpdateUsers}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={alertOpen}
                            autoHideDuration={4000}
                            onClose={handleSnackBarClose}
                        >
                            <MuiAlert
                                onClose={handleSnackBarClose}
                                severity={alertSeverity}
                                sx={{ width: '100%', fontSize: '1.2rem', padding: '12px 16px' }}>
                                {alertMessage}
                            </MuiAlert>
                        </Snackbar>
                        <ConfirmationDialog open={openDeleteConfirm} title={confirmDeleteTitle} message={confirmDeleteMessage} onConfirm={handleConfirmDelete} onCancel={handleConfirmDeleteCancel} />
                    </Box>
                </div>
                <ConfigBottomBar save={save} />
            </section>
        </>
    )
}

export default ConfigPackages;